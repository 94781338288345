import React from 'react';

const mainColor = '#4299e1';

const Success = () => (
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 609.943 568.504">
  <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
    <path id="Path_438" data-name="Path 438" d="M310.706,694.028a24.215,24.215,0,0,0,23.383-4.119c8.19-6.874,10.758-18.2,12.847-28.682l6.18-31.017-12.938,8.908c-9.3,6.406-18.818,13.019-25.26,22.3s-9.252,21.947-4.078,31.988" transform="translate(-259.581 -173.612)" fill="#e6e6e6"/>
    <path id="Path_439" data-name="Path 439" d="M312.7,733.739c-1.628-11.864-3.3-23.881-2.159-35.872,1.015-10.649,4.264-21.049,10.878-29.579a49.2,49.2,0,0,1,12.625-11.44c1.262-.8,2.424,1.2,1.167,2a46.78,46.78,0,0,0-18.5,22.326c-4.029,10.246-4.675,21.416-3.982,32.3.419,6.582,1.311,13.121,2.206,19.653a1.2,1.2,0,0,1-.808,1.422,1.163,1.163,0,0,1-1.423-.808Z" transform="translate(-259.581 -173.612)" fill="#f2f2f2"/>
    <path id="Path_442" data-name="Path 442" d="M324.424,714.7a17.825,17.825,0,0,0,15.531,8.019c7.864-.373,14.418-5.86,20.317-11.07l17.452-15.409-11.55-.553c-8.306-.4-16.827-.771-24.738,1.793s-15.208,8.726-16.654,16.915" transform="translate(-259.581 -173.612)" fill="#e6e6e6"/>
    <path id="Path_443" data-name="Path 443" d="M308.1,740.558c7.84-13.871,16.932-29.288,33.181-34.216a37.026,37.026,0,0,1,13.955-1.441c1.482.128,1.112,2.412-.367,2.285a34.4,34.4,0,0,0-22.272,5.892c-6.28,4.275-11.17,10.218-15.308,16.519-2.535,3.861-4.806,7.884-7.076,11.9C309.488,742.785,307.366,741.858,308.1,740.558Z" transform="translate(-259.581 -173.612)" fill="#f2f2f2"/>
    <path id="Path_141" data-name="Path 141" d="M935.4,569.317H503.181a5.03,5.03,0,0,1-5.024-5.024V162.908a5.03,5.03,0,0,1,5.024-5.024H935.4a5.03,5.03,0,0,1,5.024,5.024V564.292a5.029,5.029,0,0,1-5.024,5.024Z" transform="translate(-330.476 -157.884)" fill="#fff"/>
    <path id="Path_141-2" data-name="Path 141" d="M935.4,569.317H503.181a5.03,5.03,0,0,1-5.024-5.024V162.908a5.03,5.03,0,0,1,5.024-5.024H935.4a5.03,5.03,0,0,1,5.024,5.024V564.292a5.029,5.029,0,0,1-5.024,5.024ZM503.181,159.889a3.018,3.018,0,0,0-3.012,3.011V564.292a3.018,3.018,0,0,0,3.012,3.012H935.4a3.017,3.017,0,0,0,3.012-3.012V162.908A3.018,3.018,0,0,0,935.4,159.9Z" transform="translate(-330.476 -157.884)" fill="#cacaca"/>
    <path id="Path_142" data-name="Path 142" d="M707.41,262.185a3.411,3.411,0,0,0,0,6.821H894.553a3.411,3.411,0,1,0,0-6.821Z" transform="translate(-330.476 -157.884)" fill="#e4e4e4"/>
    <path id="Path_143" data-name="Path 143" d="M707.41,282.65a3.411,3.411,0,0,0,0,6.821h95.54a3.411,3.411,0,0,0,0-6.821Z" transform="translate(-314.047 -161.283)" fill="#e4e4e4"/>
    <path id="Path_142-2" data-name="Path 142" d="M543.841,392.7a3.411,3.411,0,0,0,0,6.821H894.735a3.411,3.411,0,0,0,0-6.821Z" transform="translate(-330.476 -164.28)" fill="#e4e4e4"/>
    <path id="Path_143-2" data-name="Path 143" d="M543.841,413.17a3.411,3.411,0,0,0,0,6.821H803.133a3.411,3.411,0,0,0,0-6.821Z" transform="translate(-307.591 -164.838)" fill="#e4e4e4"/>
    <path id="Path_142-3" data-name="Path 142" d="M543.841,433.172a3.411,3.411,0,0,0,0,6.821H894.735a3.411,3.411,0,0,0,0-6.821Z" transform="translate(-330.476 -165.383)" fill="#e4e4e4"/>
    <path id="Path_143-3" data-name="Path 143" d="M543.841,453.637a3.411,3.411,0,0,0,0,6.821H803.133a3.411,3.411,0,1,0,0-6.821Z" transform="translate(-307.591 -165.94)" fill="#e4e4e4"/>
    <path id="Path_142-4" data-name="Path 142" d="M543.841,474.172a3.411,3.411,0,0,0,0,6.821H894.735a3.411,3.411,0,0,0,0-6.821Z" transform="translate(-330.476 -166.5)" fill="#e4e4e4"/>
    <path id="Path_143-4" data-name="Path 143" d="M543.841,494.637a3.411,3.411,0,0,0,0,6.821H803.133a3.411,3.411,0,0,0,0-6.821Z" transform="translate(-307.591 -167.057)" fill="#e4e4e4"/>
    <path id="Path_1" data-name="Path 1" d="M599.419,324.828a49,49,0,1,1,49-49A49,49,0,0,1,599.419,324.828Z" transform="translate(-294.958 -157.884)" fill="#2f2e41"/>
    <path id="Path_2" data-name="Path 2" d="M450.678,510.1a12.248,12.248,0,0,0-14.953-11.362l-16.2-22.825-16.271,6.459,23.325,31.912a12.314,12.314,0,0,0,24.1-4.184Z" transform="translate(-275.662 -167.198)" fill="#a0616a"/>
    <path id="Path_3" data-name="Path 3" d="M419.112,508.409,370.1,444.831l18.363-57.711c1.346-14.509,10.425-18.561,10.811-18.726l.589-.253,15.979,42.612-11.732,31.286,28.8,48.432Z" transform="translate(-272.259 -165.333)" fill="#3f3d56"/>
    <path id="Path_4" data-name="Path 4" d="M589.308,312.42a12.248,12.248,0,0,0-10.172,15.787l-21.5,17.913,7.7,15.723,30.013-25.723a12.314,12.314,0,0,0-6.035-23.7Z" transform="translate(-292.845 -162.427)" fill="#e2b8be"/>
    <path id="Path_5" data-name="Path 5" d="M590.062,344.022l-59.6,53.777-58.958-13.846c-14.57-.22-19.311-8.959-19.506-9.331l-.3-.568,41.249-19.226,32.1,9.278,46.06-32.455Z" transform="translate(-284.689 -163.159)" fill="#3f3d56"/>
    <path id="Path_6" data-name="Path 6" d="M227.248,568.437h16.013l7.617-61.764H227.245Z" transform="translate(-24.514 -15.729)" fill="#e2b8be"/>
    <path id="Path_7" data-name="Path 7" d="M0,0H50.326V19.444H14.119A14.119,14.119,0,0,1,0,5.325V0Z" transform="translate(248.974 567.577) rotate(179.997)" fill="#2f2e41"/>
    <path id="Path_8" data-name="Path 8" d="M163.247,568.437H179.26l7.618-61.764H163.245Z" transform="translate(-17.61 -15.729)" fill="#e2b8be"/>
    <path id="Path_9" data-name="Path 9" d="M0,0H50.326V19.444H14.119A14.119,14.119,0,0,1,0,5.325Z" transform="translate(192.17 567.577) rotate(179.997)" fill="#2f2e41"/>
    <path id="Path_10" data-name="Path 10" d="M157.552,342.991l1.306,91.429,1.307,120.164,28.734-2.612,14.368-165.878,18.286,165.878h29.665l2.988-167.184-10.449-36.572Z" transform="translate(-19.12 -15.729)" fill="#2f2e41"/>
    <path id="Path_11" data-name="Path 11" d="M473.374,513.153c-31.265,0-60.045-14.148-60.433-14.343l-.323-.161-2.624-62.966c-.761-2.225-15.743-46.131-18.28-60.086-2.571-14.139,34.688-26.547,39.213-28l1.027-11.374,41.754-4.5L479,346.278l14.979,5.617a7.409,7.409,0,0,1,4.592,8.7l-8.325,33.856,20.333,112.013-4.378.189C495.709,511.4,484.384,513.153,473.374,513.153Z" transform="translate(-276.238 -164.672)" fill="#3f3d56"/>
    <circle id="Ellipse_1" data-name="Ellipse 1" cx="30.063" cy="30.063" r="30.063" transform="translate(135.856 121.618) rotate(-28.663)" fill="#e2b8be"/>
    <path id="Path_12" data-name="Path 12" d="M430.117,323.561c5.729,6.1,16.369,2.827,17.116-5.511a10.075,10.075,0,0,0-.013-1.945c-.385-3.693-2.519-7.046-2.008-10.945a5.74,5.74,0,0,1,1.05-2.687c4.565-6.114,15.283,2.734,19.591-2.8,2.642-3.394-.464-8.737,1.564-12.53,2.676-5.006,10.6-2.537,15.572-5.278,5.53-3.05,5.2-11.535,1.559-16.7-4.44-6.294-12.223-9.652-19.91-10.136s-15.321,1.594-22.5,4.391c-8.154,3.178-16.24,7.569-21.257,14.739-6.1,8.719-6.689,20.441-3.638,30.637C419.1,311,425.438,318.578,430.117,323.561Z" transform="translate(-277.724 -160.863)" fill="#2f2e41"/>
    <path id="Path_13" data-name="Path 13" d="M641.581,741.963h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-259.581 -173.612)" fill="#cacaca"/>
    <path id="Path_14" data-name="Path 14" d="M596.59,294.335a3.488,3.488,0,0,1-2.381-.936l-16.157-15.007a3.5,3.5,0,0,1,4.764-5.129L596.5,285.976,623.577,258.9a3.5,3.5,0,1,1,4.949,4.95l-29.461,29.461A3.492,3.492,0,0,1,596.59,294.335Z" transform="translate(-295.394 -160.755)" fill="#fff"/>
  </g>
</svg>


  
);

export default Success;
