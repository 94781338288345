import React,{ useEffect, useState } from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import Design from '../svg/Design';
import Success from '../svg/Success';
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";
import AnchorLink from 'react-anchor-link-smooth-scroll';
  
       

const Index = () =>{   
React.useEffect(()=>{  
import('gatsby-plugin-firebase-v9.0').then(firebase => {
  
  import('firebase/analytics').then(event=>{
    
     event.logEvent(event.getAnalytics(),"home")
  })
    });
},[])
  
return <Layout>
    <Helmet>
  <script type="application/ld+json">
    {`
    {
          "url": "https://infinitytechniques.com/",
          "name": "Infinity Techniques",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-9855956256",
            "contactType": "Customer Support"
          }
        }
      `}
  </script>
</Helmet>
    <section id="home" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
           Infinity Techniques
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            We develop Mobility Solutions for various applications on various platforms and help you adapt to change
             - generate business value - no matter what size your business.
          </p>   
          <p className="mt-8 md:mt-12">
            <AnchorLink href="#contact_us"> 
            <Button size="lg">Get Started</Button>
            </AnchorLink>
          </p>
         
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Mobile App Development</p>
              <p className="mt-4">
                Need to know what the versatile applications fever is about? Infinity Team is here to help you turn into a part of the versatility unrest by enabling your business.....
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Web Application Development</p>
              <p className="mt-4">
               We develop web application with the help and dedication of our developers having years of quality experience that leads them to deliver the perfect results that are superior in quality and performance.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Web Designing</p>
              <p className="mt-4">
                Businesses are always on a spree to retain their online customers. This can be achieved by perfect presentation of your business online. We are experts at taking your vision....
              </p>
            </Card>
          </div>
        </div>
         <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Web Hosting</p>
              <p className="mt-4">
                The hosting service is usually a form of Web hosting service that permits individuals and corporations to create their web site offered via net. Internet hosts tend to be businesses that provide space over a server.....
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Domain Registration</p>
              <p className="mt-4">
               Some sort of website name registrar is surely an business or maybe professional business in which copes with the reservation associated with Web domains. Some sort of website name registrar need to .....
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Digital Marketing</p>
              <p className="mt-4">
                Make your brand and promote your products with our marketing approach. Our assistance ranges from Search Engine Optimization (SEO), Email Marketing, Online Reputation Management to Social Media Marketing (SMM).
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of enthusiastic marketers will analyse and evaluate how your company stacks
            against the closest competitors
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Develop Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach. We will make your dream come true. We will design and develop your mobile app or web app.
          </p>
        </div>
      }
      secondarySlot={<Design />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Maintenance / Performance Optimization
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
           We will help you reach your goals by enhancing the performance of the process include in your web app or mobile app.
          </p>
        </div>
      }
      secondarySlot={<Success />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Growth" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="App Performance" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Quality" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-10 lg:py-20">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light">
       If you do decide that growth is your strategy, think long and hard about the resources it will take to get there.
      </p>
      <p className="mt-8">
        <AnchorLink href="#contact_us">   
        <Button size="xl">Get Started Now</Button>
        </AnchorLink>
      </p>
    </section>
     <section id="contact_us" >
    <Card className="text-center">
   
  <h2 className="mx-auto text-3xl lg:text-5xl font-semibold text-center">Contact Us</h2>
  <form  method="post" action="https://getform.io/f/f67c34e0-ca11-4cff-b050-b2bcc0892046">
  <p className="mt-8 text-xl font-light">
  <label>
    Email
    <input className="mx-8 text-gray-600 bg-gray-200" style={{padding:'.5rem'  , marginLeft:'3rem'}} type="email" name="email" />
  </label>
  </p>
   <p className="mt-8 mx-auto text-xl font-light">
  <label>
    Name
    <input className="mx-8 text-gray-600 bg-gray-200" style={{padding:'.5rem' , marginLeft:'3rem'}}  type="text" name="name" />
  </label>
  </p>
   <p className="mt-8 text-xl font-light">
  <label>
    Message
    <input className="mx-8 text-gray-600 bg-gray-200" style={{padding:'.5rem'  , marginLeft:'1rem'}} type="text" name="message"  />
  </label>
  </p>
  <p className="my-6 py-6">
 <button type="submit" style={{marginTop:'2rem'}} className="py-3 px-8 py-4 px-12 py-5 px-16 text-lg bg-primary hover:bg-primary-darker rounded text-white" >Submit</button>

 </p>
</form>


</Card> 
    </section>
  </Layout>;
}


export default Index;
