export default [
  {
    title: 'Citoyen app V2.2',
    content:
      'Infinity Techniques successfully completed this major upgrade to the Citoyen app, within the defined budget and timing. It was a pleasure working with the team',
    customerName: 'Mickaël Jordan',
    customerTitle: 'Germany',
    customerImage: 'https://firebasestorage.googleapis.com/v0/b/infinitytechniques.appspot.com/o/mickael_small.png?alt=media&token=56f1380e-0b14-487e-b09f-612c64a06f2f'
  },
  {
    title: 'Android App to track Deliveries',
    content:
      'Infinity Techniques has perfectly ported our app to Android. They have been very autonomous and asked guidance only when needed.',
    customerName: 'TWC Penrith',
    customerTitle: 'Australia',
    customerImage: 'https://firebasestorage.googleapis.com/v0/b/infinitytechniques.appspot.com/o/user.png?alt=media&token=0b164d0b-16ad-4144-9a2a-a415f1de0293'
  },
  {
    title: 'Food Watchers',
    content:
      'Great team to work with. They are expert in developing mobile apps.  I strongly recommend working with Infinity Team',
    customerName: 'Jane Smith',
    customerTitle: 'USA',
    customerImage: 'https://firebasestorage.googleapis.com/v0/b/infinitytechniques.appspot.com/o/user.png?alt=media&token=0b164d0b-16ad-4144-9a2a-a415f1de0293'
  }
];
