import React from 'react';

const mainColor = '#4299e1'; 

const Design = () => (
<svg id="Group_1" data-name="Group 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 578.109 582.703">
  <path id="Path_1" data-name="Path 1" d="M800.344,584l-8.144,2.6-13.921-30.171,12.019-3.845Z" transform="translate(-243.65 -19.03)" fill="#ffb6b6"/>
  <path id="Path_2" data-name="Path 2" d="M994.066,740.365l-26.258,8.4-.106-.332a10.731,10.731,0,0,1,6.951-13.489h0l3.632-5.173,10.113.777,2.292-.733Z" transform="translate(-432.77 -168.163)" fill="#2f2e41"/>
  <path id="Path_3" data-name="Path 3" d="M723.134,592.124h-8.55l-4.067-32.978h12.618Z" transform="translate(-243.65 -19.03)" fill="#ffb6b6"/>
  <path id="Path_4" data-name="Path 4" d="M914.435,749.546H886.867V749.2a10.731,10.731,0,0,1,10.73-10.73h0l5.036-3.82,9.4,3.821h2.407Z" transform="translate(-432.77 -168.163)" fill="#2f2e41"/>
  <path id="Path_5" data-name="Path 5" d="M871.418,400.457A7.013,7.013,0,0,0,873.532,411l-4.1,24.583,11.838-5.254,2.08-22.539a7.051,7.051,0,0,0-11.93-7.335Z" transform="translate(-414.737 -157.524)" fill="#ffb6b6"/>
  <path id="Path_6" data-name="Path 6" d="M979.343,604.38s1.994-25.97-5.074-30.792-65.34-7.251-65.34-7.251l-3.618,4.758s-.328,2.222-5.815,20.326L897.26,692.474c-.222,9.852-1.939,22.23,2.175,24.761l2,10h11l6.077-16.812a22.108,22.108,0,0,0,3.167-14.162L925,681.417a1.8,1.8,0,0,1-.472-.847,8.669,8.669,0,0,1,1.387-6.336,11.175,11.175,0,0,1,.967-1.233l12.158-54.382,16.4,63.614,10.121,22.6c-1,2.369-.334,4.515,1.479,6.505-.674,1.853,2.239,3.153,3.49,5.548l3.91,7.35,11-6,1-6c.666-1.779-1.125-2.959-2.445-6.654,2.462-2.615,2.329-5.162-.045-7.65L979.787,680l-.048-8.22-.052-8.786Z" transform="translate(-432.77 -168.163)" fill="#2f2e41"/>
  <circle id="Ellipse_1" data-name="Ellipse 1" cx="19.109" cy="19.109" r="19.109" transform="translate(481.471 253.767)" fill="#ffb6b6"/>
  <path id="Path_7" data-name="Path 7" d="M952.574,433.87l-.316-.631c-.488-.976-4.529-9.09-5.036-11.176l-5.085.6-13.242,0,1.84-2.76-4.865.635a11.165,11.165,0,0,0-4.957,1.868,7.6,7.6,0,0,0-2.249,2.318c-1.738,2.976-4.393,7.843-4.419,7.892l-.254.466-2.206-3.711c-.029-.144-2.763-14.4,6.387-19.241a14.726,14.726,0,0,1,14-7.989,6.026,6.026,0,0,1,4.1-2.036c2.171-.113,4.424,1.027,6.686,3.4,3.006,3.156,7.122,7.478,9.612,12.277l.687.98a7.723,7.723,0,0,1,1.374,4.8,16.008,16.008,0,0,1-.545,8.166Z" transform="translate(-432.77 -157.542)" fill="#2f2e41"/>
  <path id="Path_8" data-name="Path 8" d="M979.254,467.243l-20.771-5.816-10.574-7.844L927.139,456.9l-.09.117c-3.637,4.724-9.243,9.391-15.2,9.577a20.868,20.868,0,0,0-4.542.652l-1.994,36.46v32.6a1.912,1.912,0,0,1,1.255,1.393,26.478,26.478,0,0,1-.163,10.03,16.769,16.769,0,0,1-1.092,3.35,8.844,8.844,0,0,1,0,7.7c-1.775,1.693-1.546,3.537,0,5.48V571.1c33.713,28.128,68.871,4.043,68.871,4.043L973.054,521.6Z" transform="translate(-432.77 -161.445)" fill="#3f3d56"/>
  <path id="Path_9" data-name="Path 9" d="M911.964,472.251l-4.659-5.008-25.673-2.908,2.409-39.431-14-9.985-6.339,34.217c-2.18,1.886-2.584,3.743-1.032,5.569-1.745,3.49-2.194,6.328-1.6,8.641l-1.312,7.084,47.5,33.273Z" transform="translate(-432.77 -158.994)" fill="#3f3d56"/>
  <path id="Path_10" data-name="Path 10" d="M975.464,601.181a7.013,7.013,0,0,1,3.223-10.26l-1.463-24.88,11.212,6.483-.329,22.632a7.051,7.051,0,0,1-12.642,6.024Z" transform="translate(-432.77 -163.218)" fill="#ffb6b6"/>
  <path id="Path_11" data-name="Path 11" d="M971.451,473.465l7.8-6.222s7.979,4.4,10.407,12.292,6.677,52.81,6.677,52.81l.655,16.725c.839,2.653.62,5.019-1.381,6.9,3.007,3.11,2.558,5.7-1.548,7.741l-4.259,26.3-13.369-2.775,1.692-51.855L967.2,501.994Z" transform="translate(-432.77 -161.772)" fill="#3f3d56"/>
  <path id="Path_12" data-name="Path 12" d="M437.435,267.476H434.7V192.518a43.384,43.384,0,0,0-43.384-43.384H232.5a43.384,43.384,0,0,0-43.384,43.384v411.23A43.384,43.384,0,0,0,232.5,647.132h158.81A43.384,43.384,0,0,0,434.7,603.747V320.833h2.736Z" transform="translate(-189.121 -149.133)" fill="#3f3d56"/>
  <path id="Path_13" data-name="Path 13" d="M393.066,160.421h-20.73a15.393,15.393,0,0,1-14.251,21.206H267.1a15.393,15.393,0,0,1-14.251-21.206H233.49a32.4,32.4,0,0,0-32.4,32.4V603.446a32.4,32.4,0,0,0,32.4,32.4H393.066a32.4,32.4,0,0,0,32.4-32.4h0V192.819a32.4,32.4,0,0,0-32.4-32.4Z" transform="translate(-189.121 -149.133)" fill="#fff"/>
  <path id="Path_14" data-name="Path 14" d="M391.531,314.215a77.915,77.915,0,0,1-4.911,27.322,75.776,75.776,0,0,1-5.427,11.572,78.277,78.277,0,0,1-135.83,0q-1.041-1.8-1.974-3.662a78.26,78.26,0,1,1,148.142-35.231Z" transform="translate(-189.121 -149.133)" fill="#f1f1f1"/>
  <circle id="Ellipse_2" data-name="Ellipse 2" cx="14.96" cy="14.96" r="14.96" transform="translate(96.782 143.434)" fill="#ff6582"/>
  <path id="Path_15" data-name="Path 15" d="M243.389,349.447c6.591-7.123,15.4-18.433,23.668-10.261,4.148,3.855,10.639,13.406,16.853,7.162l48.522-48.522a9.633,9.633,0,0,1,13.776.247l40.412,43.465a75.8,75.8,0,0,1-5.427,11.572H245.363Z" transform="translate(-189.121 -154.241)" fill="#2f2e41"/>
  <path id="Path_680" data-name="Path 680" d="M349.995,473.123a2.022,2.022,0,1,0,0,4.043h21.214a2.022,2.022,0,1,0,0-4.043Z" transform="translate(-237.714 -159.449)" fill="#2f2e41"/>
  <path id="Path_680-2" data-name="Path 680" d="M254.419,443.43a2.022,2.022,0,0,0,0,4.043H372.137a2.022,2.022,0,1,0,0-4.043Z" transform="translate(-189.121 -158.503)" fill="#e5e5e5"/>
  <path id="Path_680-3" data-name="Path 680" d="M254.419,458.277a2.022,2.022,0,1,0,0,4.043H372.137a2.022,2.022,0,0,0,0-4.043Z" transform="translate(-189.121 -158.976)" fill="#e5e5e5"/>
  <path id="Path_680-4" data-name="Path 680" d="M349.995,568.39a2.022,2.022,0,1,0,0,4.043h21.214a2.022,2.022,0,1,0,0-4.043Z" transform="translate(-237.714 -162.482)" fill="#2f2e41"/>
  <path id="Path_680-5" data-name="Path 680" d="M254.419,538.7a2.022,2.022,0,0,0,0,4.043H372.137a2.022,2.022,0,0,0,0-4.043Z" transform="translate(-189.121 -161.537)" fill="#e5e5e5"/>
  <path id="Path_680-6" data-name="Path 680" d="M254.419,553.543a2.022,2.022,0,1,0,0,4.043H372.137a2.022,2.022,0,1,0,0-4.043Z" transform="translate(-189.121 -162.009)" fill="#e5e5e5"/>
  <path id="Path_16" data-name="Path 16" d="M860.2,377.02l-16.153-7.3a13.163,13.163,0,0,1-18.576,11.5L754.58,349.164a13.163,13.163,0,0,1-3.633-21.545L735.861,320.8a27.706,27.706,0,0,0-36.66,13.83L554.526,654.585a27.706,27.706,0,0,0,13.83,36.66h0L692.7,747.467a27.706,27.706,0,0,0,36.66-13.83h0L874.03,413.68A27.706,27.706,0,0,0,860.2,377.02Z" transform="translate(-392.161 -168.163)" fill="#e4e4e4"/>
  <path id="Path_17" data-name="Path 17" d="M716.3,529.367a68.129,68.129,0,0,1-39.633-55.408c-.117-1.18-.2-2.394-.251-3.6A67.16,67.16,0,0,1,682.4,439.5a67.918,67.918,0,1,1,110.116,75.817,66,66,0,0,1-8.432,7.212,68.135,68.135,0,0,1-67.783,6.835Zm55.142-121.949a65.993,65.993,0,0,0-87.22,32.909,65.181,65.181,0,0,0-5.807,29.944c.049,1.174.13,2.349.243,3.494a65.939,65.939,0,0,0,104.257,47.144,64.007,64.007,0,0,0,8.179-7,65.947,65.947,0,0,0-19.652-106.495Z" transform="translate(-362.192 -159.362)" fill="#fff"/>
  <rect id="Rectangle_1" data-name="Rectangle 1" width="2" height="132.848" transform="translate(364.179 245.419) rotate(-20.96)" fill="#fff"/>
  <rect id="Rectangle_2" data-name="Rectangle 2" width="132.848" height="2" transform="matrix(0.934, -0.358, 0.358, 0.934, 319.792, 330.965)" fill="#fff"/>
  <path id="Path_18" data-name="Path 18" d="M743.321,626.295,625.966,573.23a3.607,3.607,0,0,1-1.8-4.767l11.04-24.415a3.607,3.607,0,0,1,4.767-1.8L757.33,595.315a3.607,3.607,0,0,1,1.8,4.767L748.088,624.5a3.607,3.607,0,0,1-4.767,1.8ZM639.15,544.072a1.6,1.6,0,0,0-2.12.8l-11.04,24.415a1.6,1.6,0,0,0,.8,2.12l117.356,53.065a1.6,1.6,0,0,0,2.12-.8l11.04-24.415a1.6,1.6,0,0,0-.8-2.12Z" transform="translate(-343.489 -163.59)" fill="#fff"/>
  <path id="Path_19" data-name="Path 19" d="M714.893,689.166,597.537,636.1a3.607,3.607,0,0,1-1.8-4.767l11.04-24.415a3.607,3.607,0,0,1,4.767-1.8L728.9,658.186a3.607,3.607,0,0,1,1.8,4.767l-11.04,24.415a3.607,3.607,0,0,1-4.767,1.8ZM610.721,606.944a1.6,1.6,0,0,0-2.12.8l-11.04,24.415a1.6,1.6,0,0,0,.8,2.12l117.356,53.065a1.6,1.6,0,0,0,2.12-.8l11.04-24.415a1.6,1.6,0,0,0-.8-2.12Z" transform="translate(-333.394 -168.163)" fill="#fff"/>
  <path id="Path_20" data-name="Path 20" d="M620.191,503.246a48.493,48.493,0,0,0-11.1-21.435,73.246,73.246,0,0,0-20.28-15.943,96.27,96.27,0,0,0-25.963-9.5,159.756,159.756,0,0,0-34.819-3.136c-11.766.1-23.494,1.085-35.188,2.181q-2.229.209-4.458.422c-1.385.132-1.4,2.086,0,1.953,11.521-1.1,23.059-2.177,34.646-2.509a172.82,172.82,0,0,1,34.38,2.032,99.189,99.189,0,0,1,26.932,8.5,75.382,75.382,0,0,1,20.8,14.762A48.766,48.766,0,0,1,617.367,500.9c.272.949.508,1.906.714,2.869C618.346,505,620.455,504.476,620.191,503.246Z" transform="translate(-271.942 -159.76)" fill="#2f2e41"/>
  <path id="Path_21" data-name="Path 21" d="M499.49,435.767l-23.962,25.488a1.514,1.514,0,0,0,.3,2.356l21.618,10.333c1.733.829,3.257-1.757,1.514-2.59l-21.618-10.334.3,2.356,23.962-25.488c1.322-1.406-.8-3.532-2.121-2.121Z" transform="translate(-276.785 -158.807)" fill="#2f2e41"/>
  <path id="Path_22" data-name="Path 22" d="M1009.689,750.867H552.395a1.191,1.191,0,1,1,0-2.381h457.294a1.191,1.191,0,1,1,0,2.381Z" transform="translate(-432.77 -168.163)" fill="#cacaca"/>
</svg>




);

export default Design;
